@charset 'UTF-8';
@import "/Users/nagayamegumi/Sites/fytte/src/scss/utility/_media-queries.scss";
@import "/Users/nagayamegumi/Sites/fytte/src/scss/utility/_mixin.scss";


.page-contents {
  padding-bottom: 76px;
  position: relative;
  .cm-bg-pink01 {
    position: absolute;
    left: 0; right: 0; top: 220px;
    height: 650px;
  }
}
.page-ttl {
  text-align: center;
}
.keyvisual {
  padding: 61px 0 49px;
  &-ttl {
    padding: 6px 36px 0 36px;
  }
  @include SP {
    padding: 23px 0 29px;
    &-ttl {
      padding: 0 10px 0 14px;
    }
  }
}
.sec-report01 {

}
.cm-list-report {
  position: relative;
  z-index: 29;
  .item {
    padding-bottom: 0;
    &-ttl {
      margin-bottom: 14px;
    }
    &-except {
    }
  }
  @include PC {
    .item {
      margin-bottom: 65px;
    }
  }
  @include SP {
    padding: 0 13px;
    .item {
      width: 100%;
      margin: 0 0 34px;
      padding-bottom: 17px;
      border-bottom: 1px solid #D3D3D3;
      &:last-child {
        margin-bottom: 5px;
      }
      &-thumb {
        margin-bottom: 17px;
        .js-image {
          @include aspect-ratio(160, 100);
        }
      }
      &-ttl {
        margin-bottom: 6px;
        @include font(18,24);
      }
      &-except {
        @include font(13,21);
        display: block;
        margin-bottom: 7px;
      }
      &-date {
        @include font(14,17);
      }
    }
  }
}

.wp-pagenavi {
  margin: 31px 0 0;
}

.single {
  .page-contents {
    padding: 58px 0 34px;
    @include SP {
      padding: 25px 0 50px;
    }
  }
}

.sg-block01 {
  position: relative;
  .block-white {
    background: #fff;
    position: relative;
    z-index: 29;
    box-shadow:0 4px 10px rgba(0,0,0,0.05);
    padding: 47px 80px 55px;
    @include PC {
      @include max-screen(1100px) {
        padding: 47px 40px 55px;
      }
    }
  }
  .sg-ttl {
    @include font(26,28,0,bold);
    border-bottom: 1px solid #707070;
    padding-bottom: 24px;
    margin-bottom: 28px;
  }
  .sg-excerpt {
    @include font(16,28,0,400);
    border-bottom: 2px dashed #A7D1AD;
    padding-bottom: 26px;
    margin-bottom: 38px;
  }
  .sg-thumb {
    text-align: center;
    margin-bottom: 21px;
  }
  .sg-wcm01 {
    padding: 0 80px;
  }
  .txt-note01 {
    @include ffBun;
    color: #FBB5C1;
    @include font(13,24,0,400);
    text-align: right;
    margin-top: 44px;
  }
  .cmsContent {
    h2 {
      @include font(24,28,0,bold);
      position: relative;
      padding: 35px 0 0 30px;
      min-height: 86px;
      margin-bottom: 11px;
      &:after {
        position: absolute; content: "";
        left: 0; top: 0;
        text-align: center;
        width: 86px; height: 86px;
        background: $yellow02;
        @include border-radius(50%);
        z-index: -1;
      }
    }
    h3 {
      @include font(20,24,0,bold);
      position: relative;
      padding: 0 0 0 29px;
      margin-bottom: 14px;
      &:before {
        content: "";
        position: absolute;
        left: 3px;
        top: 50%;
        width: 0;
        height: 0;
        border-bottom: 7px solid transparent;
        border-left: 12px solid #F86852;
        border-top: 7px solid transparent;
        @include transform(rotate(240deg));
        margin-top: -10px;
      }
    }
    p {
      @include font(16,28);
      margin-bottom: 38px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include SP {
    .block-white {
      padding: 28px 20px 24px;
    }
    .sg-ttl {
      @include font(20,28);
      padding-bottom: 9px;
      margin-bottom: 19px;
    }
    .sg-excerpt {
      @include font(15,26);
      padding-bottom: 18px;
      margin-bottom: 34px;
    }
    .sg-thumb {
      margin-bottom: 28px;
    }
    .sg-wcm01 {
      padding: 0 80px;
    }
    .txt-note01 {
      margin-top: 33px;
    }
    .cmsContent {
      h2 {
        @include font(18,27);
        padding: 12px 0 0 20px;
        min-height: 52px;
        margin-bottom: 7px;
        &:after {
          width: 50px; height: 50px;
        }
      }
      h3 {
        @include font(16,24);
        padding: 0 0 0 14px;
        margin-bottom: 12px;
        &:before {
          left: 0;
          border-bottom: 5px solid transparent;
          border-left: 7px solid #F86852;
          border-top: 5px solid transparent;
          margin-top: -4px;
        }
      }
      p {
        @include font(15,26);
        margin-bottom: 18px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.sg-block02 {
  padding: 56px 0 0;
  @include PC {
    .cm-ttl02.is-center {
      padding: 8px 36px 0;
    }
  }
  .cm-list-report {
    margin-top: 43px;
  }
  @include SP {
    padding: 65px 0 0;
    .cm-ttl02.is-center {
      padding: 0 14px 0;
    }
    .cm-list-report {
      margin-top: 13px;
      padding: 0;
      .item {
        width: 46%;
        margin: 0 8% 30px 0;
        padding-bottom: 0;
        border: 0;
        &:nth-child(2n) {
          margin-right: 0;
        }
        &-thumb {
          margin-bottom: 9px;
        }
        &-ttl {
          @include font(13,21);
          margin-bottom: 13px;
        }
        &-except {
          display: none;
          @include font(13,21);
        }
        &-date {
          @include font(10,12);
        }
      }
    }
  }
}
